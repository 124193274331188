<style scoped>
</style>

<template>
  <AppPage>
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="name">名称</Option>
                  <Option value="code">编码</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col v-if="!type">类型：</i-col>
            <i-col v-if="!type">
              <Dropdown @on-click="type => onSearch({ type })">
                <template v-for="item in scmHouseTypeList">
                  <Tag v-if="query.type === String(item.value)" :key="item.value" size="large" style="cursor: pointer;" :color="item.color">{{ item.label }}<Icon type="ios-arrow-down"></Icon></Tag>
                </template>
                <Tag size="large" v-if="query.type === 'all'" style="cursor: pointer;" color="default">全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem :selected="query.type === 'all'" name="all">全部</DropdownItem>
                  <DropdownItem v-for="item in scmHouseTypeList" :key="item.value" :selected="query.status === item.value" :name="String(item.value)">{{ item.label }}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" icon="md-refresh">刷新</Button>
          <Button v-if="!choose && $authFunsProxy.add" style="margin-left: 10px;" @click="addClick" icon="md-add" type="primary">新增</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="showDataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:type="{ row }">
          <Tag class="noborder" v-if="row.typeData" :color="row.typeData.color">{{ row.typeData.label }}</Tag>
        </template>
        <template v-slot:action="{ row }">
          <template v-if="choose">
            <Button @click="$emit('on-choose', row)">选取</Button>
          </template>
          <template v-else>
            <Button @click="itemUnit(row)" size="small" icon="md-apps" v-if="$authFunsProxy.unitManage">单元</Button>
            <Button @click="itemUpdate(row)" style="margin-left: 10px" v-if="$authFunsProxy.update" size="small" icon="md-settings">修改</Button>
            <Poptip style="margin-left: 10px" transfer title="确定删除吗?" v-if="$authFunsProxy.del" confirm @on-ok="itemDel(row)">
              <Button size="small" icon="md-trash" :loading="loading.del" type="warning">删除</Button>
            </Poptip>
          </template>
        </template>
      </Table>
    </template>
    <template v-slot:footer>
      <Drawer placement="right" :title="formData && formData.id ? '修改' : '新增'" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <HouseForm :data="formData" ref="form" />
        </DrawerAction>
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import DrawerAction from '../../components/drawer/action.vue'
import request from '../../api/index'
import HouseForm from '../../components/stock/form/house.vue'

export default {
  components: { AppPage, HouseForm, DrawerAction },
  props: {
    choose: { type: Boolean, default: false },
    type: { type: String, default: null }
  },
  data () {
    return {
      query: {
        key: 'name',
        value: null,
        type: this.type
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false
      },
      formData: null
    }
  },
  computed: {
    showDataList () {
      const key = this.query.key
      const value = this.query.value
      const type = this.query.type !== 'all' ? this.query.type : null
      return this.dataList.filter(item => {
        if (key && value && (!item[key] || String(item[key]).indexOf(value) < 0)) {
          return false
        }
        if (type && item.type !== type) {
          return false
        }
        return true
      })
    },
    scmHouseTypeList () {
      return this.$store.getters.scmHouseTypeList
    },
    columns () {
      return [
        { title: '名称', key: 'name' },
        { title: '编码', key: 'code' },
        { title: '类型', key: 'type', slot: 'type' },
        { title: '备注', key: 'remark', maxWdith: 200, ellipsis: true, tooltip: true },
        { title: '操作', key: 'action', slot: 'action', width: this.choose ? 90 : 270 },
      ]
    }
  },
  watch: {
    type () {
      this.query.type = this.type
    }
  },
  methods: {
    itemUnit (item) {
      this.$router.push({
        name: 'scm.stock.unit',
        query: {
          storehouseId: item.id,
          back: true
        }
      })
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (!data) {
          return this.$Message.warning('请完整填写表单')
        }
        this.loading.submit = true
        if (data.id) {
          await request.put('/storehouse/' + data.id, data)
        } else {
          await request.post('/storehouse', data)
        }
        this.$Message.success('操作成功')
        this.status.form = false
        if (data.id) {
          this.loadData()
        } else {
          this.refresh()
        }
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    addClick () {
      this.formData = null
      this.status.form = true
    },
    itemUpdate (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.form = true
    },
    async itemDel (item) {
      this.loading.del = true
      try {
        await request.delete('/storehouse/' + item.id)
        this.$Message.success('已删除')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.del = false
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
    },
    refresh () {
      this.query.key = 'name'
      this.query.value = null
      this.query.type = this.type
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      this.loading.load = true
      try {
        this.dataList = await this.$store.dispatch('loadStoreHouse', true)
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
