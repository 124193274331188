<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="名称" prop="name">
      <Input v-model="formData.name" placeholder="请输入名称" />
    </FormItem>
    <FormItem label="编码" prop="code">
      <Input v-model="formData.code" placeholder="请输入编码" />
    </FormItem>
    <FormItem label="类型" prop="type">
      <RadioGroup v-model="formData.type">
        <Radio v-for="item in scmHouseTypeList" :label="item.value" :key="item.value" border>{{ item.label }}</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
function getDefault (type) {
  return {
    id: null,
    name: null,
    code: null,
    type: type || 'store',
    remark: null
  }
}

const rules = Object.freeze({
  name: [
    { required: true, message: '请输入名称' }
  ],
  type: [
    { required: true, message: '请选择类型' }
  ]
})

export default {
  props: {
    data: { type: Object, default: null },
    type: { type: String, default: 'store' }
  },
  data () {
    return {
      formData: this.data || getDefault(this.type),
      rules
    }
  },
  computed: {
    scmHouseTypeList () {
      return this.$store.getters.scmHouseTypeList
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault(this.type)
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
